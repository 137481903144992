import { PublicClientApplication, BrowserAuthError } from '@azure/msal-browser'
import { msalConfig, loginRequest } from '@/config'
import { API_URL } from "@/constants";
import utils from "@/services/utils";

const msalInstance = new PublicClientApplication(msalConfig)

async function hasRole(section: string, rolename: string) {
    await getUserRoles(localStorage.getItem('user_id') || '-1');
    await getUserCompanyAdmins(localStorage.getItem('user_id') || '-1');
    interface UserRole {
        UserID: number;
        RoleID: number;
        Section: string;
        RoleName: string;
    }
    const roles: UserRole[] = JSON.parse(localStorage.getItem("user_roles") || "[]");
    const userId = Number(localStorage.getItem("user_id"));
    const hasRole = roles.some(role => {
        return role.UserID == userId && role.Section === section && role.RoleName === rolename;
    });

    return hasRole;
}

async function hasRoleID(roleID: number) {
    await getUserRoles(localStorage.getItem('user_id') || '-1');
    await getUserCompanyAdmins(localStorage.getItem('user_id') || '-1');

    interface UserRole {
        UserID: number;
        RoleID: number;
        Section: string;
        RoleName: string;
    }
    const roles: UserRole[] = JSON.parse(localStorage.getItem("user_roles") || "[]");
    const userId = Number(localStorage.getItem("user_id"));
    const hasRole = roles.some(role => {
        return role.UserID == userId && role.RoleID === roleID;
    });

    return hasRole;
}

async function isHumanCapitalAdmin() {
    return await hasRole("HumanCapital", "Admin");
}

async function isHumanCapitalIT() {
    return await hasRole("HumanCapital", "IT");
}

async function isCompensationAdmin() {
    return await hasRole("Compensation", "Admin");
}

async function isCompanyAdmin() {
  return await hasRole("HumanCapital", "CompanyAdmin");
}

function isAdminForCompany(companyID: number) {
  let admin_companies: string | null = localStorage.getItem("admin_companies")
  if (admin_companies != null) {
    let acs: number[] = JSON.parse(admin_companies)
    if (acs.includes(companyID)) {
      return true
    }
    else {
      return false
    }
  }
  else {
    console.log("admin_companies is null")
    return true
  }
}

async function initMsal() {
  await msalInstance.initialize()
}

async function login() {
  let accountUsername: string = ""
  let accountName: string = ""
  let idToken: string = ""

  try {
    console.log("Waiting for sign-in")
    let response = await msalInstance.loginPopup(loginRequest)
    console.log("Login response: ", response)
    idToken = response.idToken
    accountUsername = response.account.username
    accountName = String(response.account.name)

    if (typeof (Storage) !== "undefined") {
      localStorage.setItem("access_token", idToken)
      localStorage.setItem("username", accountUsername)
      localStorage.setItem("fullname", accountName)
    } else {
      console.error("localStorage is not supported by this browser.");
    }

    await setUserID(accountUsername, idToken)
    //update roles when this check is made
    await getUserRoles(localStorage.getItem("user_id") || "-1");
    await getUserCompanyAdmins(localStorage.getItem("user_id") || "-1")
    return true

  } catch (error: any) {
    if (error instanceof BrowserAuthError && error.errorCode === "popup_window_error") {
      console.error("Popup was blocked by the browser.");
      // You can display a message to the user or take other actions here
    } else if (error instanceof BrowserAuthError && error.errorCode === "interaction_in_progress") {
      console.error("Interaction in progress: ", error);
      msalInstance.handleRedirectPromise()
      return await login()
    } else if (error instanceof BrowserAuthError && error.errorCode === "hash_empty_error") {
      console.error("Empty Hash: ", error);
      msalInstance.handleRedirectPromise()
    } else if (error instanceof BrowserAuthError) {
      console.error("Other BrowserAuthError: ", error);
      msalInstance.handleRedirectPromise()
    } else {
      console.error("Other error during login:", error);
      msalInstance.clearCache()
      // Handle other login errors
    }
  }
  return false
}

function removeAllLocalStorage() {
  localStorage.removeItem("fullname");
  localStorage.removeItem("username");
  localStorage.removeItem("access_token");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_roles");
  localStorage.removeItem("user_roles_lastchecked");
  localStorage.removeItem("admin_companies");
  localStorage.removeItem("admin_companies_lastchecked");
  sessionStorage.clear()
}

async function logout() {
  removeAllLocalStorage()
  msalInstance.logoutRedirect({account: msalInstance.getActiveAccount()})
}

async function clearSessions() {
  removeAllLocalStorage()
}

async function isAuthenticated() {
  console.log("Checking Authentication")
  try {
    if (msalInstance) {
      const account = msalInstance.getAllAccounts()
      if (account.length > 0) {
        msalInstance.setActiveAccount(account[0])
        return true
      }
    }
  } catch (error) {
    console.error("ERROR", error)
  }
  return false
}

async function getAccessToken() {
  try {
    if (msalInstance) {
      let refreshRequest = {
        account: msalInstance.getAllAccounts()[0],
        scopes: loginRequest.scopes,
      }
      const response = await msalInstance.acquireTokenSilent(refreshRequest);
      if (response.accessToken && response.accessToken !== '') {
        localStorage.setItem("access_token", response.accessToken);
        await setUserID(response.account.username, response.accessToken)
      } else {
        throw new Error("getAccessToken(): access token is empty or undefined");
      }
    } else {
      throw new Error("getAccessToken(): msalInstance was not instantiated properly and is null")
    }
  } catch (error) {
    console.log('Error acquiring token silently:', error);
    await clearSessions()
  }
}

async function getUserCompanyAdmins(userID: string) {
  const storedTime = localStorage.getItem('admin_companies_lastchecked');
  let elapsedTime = (new Date().getTime() - Number(storedTime));
  elapsedTime /= (1000*60);
  try {
    if (elapsedTime >= 1) {
      let companies = {}
      const response = await utils.httpRequest(`/Auth/${userID}/CompanyAdmins`, utils.REQUEST.GET, (data:any) => {
        companies = data.map((item: any) => item.CompanyID)
      })
      localStorage.setItem("admin_companies", JSON.stringify(companies));

      localStorage.setItem('admin_companies_lastchecked', String(new Date().getTime()));
      }
  } catch (err) {
    if (err instanceof Error) {
      console.log("Error:", err.message);
    } else {
      console.log("An error occurred:", err);
    }
  }
}

async function getUserRoles(userID: string) {
  const storedTime = localStorage.getItem('user_roles_lastchecked');
  let elapsedTime = (new Date().getTime() - Number(storedTime));
  elapsedTime /= (1000*60);

  try {
    if (elapsedTime >= 1) {
      let roles = {}
      const response = await utils.httpRequest(`/Auth/${userID}/roles`, utils.REQUEST.GET, (data:any) => {
        roles = data
      })
      console.log("Retrieved user roles");
      localStorage.setItem("user_roles", JSON.stringify(roles));

      localStorage.setItem('user_roles_lastchecked', String(new Date().getTime()));
    }
  } catch (err) {
    if (err instanceof Error) {
      console.log("Error:", err.message);
    } else {
      console.log("An error occurred:", err);
    }
  }
}

async function setUserID(username: string, token: string) {
  try {
    const response = await fetch(`${API_URL}/Auth/${username}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!response.ok) {
      console.log(`HTTP error! status: ${response.status}`);
      return "-1";
    } else {
      const data = await response.json();
      localStorage.removeItem("user_id");                             // Fix the historical addition of user_id
      data.UserID ? localStorage.setItem("user_id", data.UserID) : "" // Save userId into localStorage 
    }
  } catch (err) {
    if (err instanceof Error) {
      console.log("Error:", err.message);
    } else {
      console.log("An error occurred:", err);
    }
  }
}

async function validRefreshToken() {
  if (msalInstance) {
    const account = msalInstance.getAllAccounts();
    if (account.length > 0) {
      msalInstance.setActiveAccount(account[0]);
      return true
    }
  }
  return false
}

export default {
  login,
  logout,
  clearSessions,
  isAuthenticated,
  getAccessToken,
  initMsal,
  validRefreshToken,
  isHumanCapitalAdmin,
  isHumanCapitalIT,
  isCompensationAdmin,
  isCompanyAdmin,
  isAdminForCompany,
  hasRoleID
}
